.map-modal {
  position: fixed; /* Positions the element relative to the browser window */
  top: 20%; /* Positions the top edge of the element to the middle of the viewport */
  right: 0%; /* Positions the left edge of the element to the middle of the viewport */
  margin: 50px;
  max-width: 400px;
  z-index: 1500;
  /* Rest of your CSS for positioning, shadow, etc. */
}

.map-modal-text{
  overflow-y: auto; /* Enables scrolling when content overflows */
  padding: 16px;
  max-height: 200px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

/* Hide scrollbar for WebKit browsers like Chrome and Safari */
.map-modal-text::-webkit-scrollbar {
  display: none;
}